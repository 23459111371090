import React, { ReactElement, useContext } from 'react'
import Image from 'components/Image'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import cn from 'classnames'
import { StaticImageData } from 'next/image'

type Props = {
  content: ReactElement
  imageContent?: ReactElement | null
  links?: ReactElement
  imageSrc: string | StaticImageData
  size?: 'tiny' | 'small' | 'medium' | 'large'
}

const HeroSection = ({
  content,
  imageContent,
  imageSrc,
  size = 'medium',
  links
}: Props) => {
  const { albertsons } = useContext(ShiftPageContext)

  const heightClass = cn({
    'min-h-[16rem] sm:min-h-[40vh]': size === 'tiny',
    'min-h-[20rem] sm:min-h-[46vh]': size === 'small',
    'min-h-[24rem] sm:min-h-[50vh]': size === 'medium',
    'min-h-[26rem] sm:min-h-[54vh]': size === 'large'
  })
  return (
    <div className={cn('relative w-full', heightClass)}>
      <div className="flex relative sm:flex-row flex-col items-stretch">
        {links && (
          <div className="absolute sm:hidden top-0 right-0 bottom-0 z-30">
            {links}
          </div>
        )}
        <div className={cn('sm:w-1/2 w-full relative', heightClass)}>
          {imageContent && (
            <div className="z-20 relative mt-20 mb-28">{imageContent}</div>
          )}
          <Image
            alt="truck image"
            className="object-cover object-center"
            layout="fill"
            sizes="50vw"
            src={imageSrc}
            priority
          />
          <div
            className={cn('w-full h-full bg-black z-2 absolute top-0 left-0', {
              'bg-opacity-50': imageContent,
              'sm:bg-opacity-10 bg-opacity-60': !imageContent
            })}
          />
        </div>
        <div
          className={cn(
            'sm:w-1/2 w-full sm:static flex flex-col justify-end md:justify-center z-20 absolute sm:relative bottom-0',
            {
              'sm:bg-albertsons-neutral1': albertsons,
              'sm:bg-background-dark': !albertsons
            }
          )}
        >
          <div className="sm:p-6 p-4 pr-20">{content}</div>
        </div>
      </div>
    </div>
  )
}

export default HeroSection
