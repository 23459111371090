import InstagramIcon from 'components/icons/InstagramIcon'
import React, { ReactElement } from 'react'
import { InformationCircleIcon } from '@heroicons/react/solid'
import LinkBaseIcon from 'components/icons/LinkBaseIcon'
import TwitterIcon from 'components/icons/TwitterIcon'
import FacebookIcon from 'components/icons/FacebookIcon'
import TiktokIcon from 'components/icons/Tiktok'

export type HeroLinksType = {
  twitter: string | null
  facebook: string | null
  instagram: string | null
  website: string | null
  tiktok?: string | null
  info?: string | null
}

const SocialMediaLink = ({
  children,
  href,
  ariaLabel,
  prefix = ''
}: {
  children: ReactElement
  href: string | null
  ariaLabel: string
  prefix?: string
}) =>
  href ? (
    <a
      aria-label={`${ariaLabel}, opens in a new window`}
      href={`${prefix}${href}`}
      rel="noreferrer"
      target="_blank"
    >
      {children}
    </a>
  ) : null

const LinksSection = ({
  socialLinks: { facebook, instagram, twitter, website, tiktok, info }
}: {
  socialLinks: HeroLinksType
}) => {
  if (facebook || instagram || twitter || website || info) {
    return (
      <div className="sm:bg-transparent bg-gray-100 bg-opacity-50 p-2 sm:p-0 flex flex-col justify-center sm:justify-start sm:flex-row sm:static absolute top-0 right-0 bottom-0 sm:gap-x-2 gap-y-2 sm:gap-y-0 pt-16">
        {info && (
          <a aria-label="Additional information" href={info}>
            <LinkBaseIcon>
              <InformationCircleIcon className="w-7 text-white" />
            </LinkBaseIcon>
          </a>
        )}
        <SocialMediaLink
          ariaLabel="facebook link"
          href={facebook}
          prefix="https://www.facebook.com/"
        >
          <LinkBaseIcon>
            <FacebookIcon className="w-4 text-white" />
          </LinkBaseIcon>
        </SocialMediaLink>
        <SocialMediaLink
          ariaLabel="instagram link"
          href={instagram}
          prefix="https://www.instagram.com/"
        >
          <LinkBaseIcon>
            <InstagramIcon className="w-6 text-white" />
          </LinkBaseIcon>
        </SocialMediaLink>
        <SocialMediaLink
          ariaLabel="twitter link"
          href={twitter}
          prefix="https://twitter.com/"
        >
          <LinkBaseIcon>
            <TwitterIcon className="w-6 text-white" />
          </LinkBaseIcon>
        </SocialMediaLink>
        {tiktok && (
          <SocialMediaLink
            ariaLabel="tiktok link"
            href={tiktok}
            prefix="https://www.tiktok.com/@"
          >
            <LinkBaseIcon>
              <TiktokIcon className="w-6 text-white" />
            </LinkBaseIcon>
          </SocialMediaLink>
        )}
        <SocialMediaLink ariaLabel="website link" href={website}>
          <LinkBaseIcon>
            <span className="text-white text-xs font-bold">www</span>
          </LinkBaseIcon>
        </SocialMediaLink>
      </div>
    )
  }
  return null
}

export default LinksSection
