import smoothVerticalScrolling from 'utils/smoothScrolling'
import ButtonLink from 'components/ui/ButtonLink'
import pathFromUrl from 'utils/pathFromUrl'
import Button from 'components/ui/Button'
import React from 'react'
import isString from 'lodash/isString'

export type ActionType =
  | 'viewMenu'
  | 'viewSchedule'
  | 'scrollToSchedule'
  | 'bookTruck'
  | 'socialMedia'
  | 'market'
  | 'goBack'
  | React.ReactElement

const TruckActions = ({
  truckId,
  menuUrl,
  scheduleUrl,
  actions
}: {
  truckId: number
  menuUrl: string
  scheduleUrl: string
  actions: ActionType[]
}) => {
  const scrollToSchedule = () => {
    const element = document.getElementById('schedule')
    if (element) {
      smoothVerticalScrolling({ element, offset: 150 })
    }
  }

  return (
    <div className="w-72 lg:w-auto">
      <div className="flex lg:flex-row flex-col">
        <div className="flex lg:mb-0 mb-2 w-full lg:w-auto flex-wrap">
          {actions.includes('viewMenu') && (
            <div className="pr-2 pt-2 w-1/2 lg:w-auto">
              <ButtonLink
                color="secondary"
                href={pathFromUrl(menuUrl)}
                size="small"
                variant="transparent"
                fullWidth
                shadow
              >
                View Menu
              </ButtonLink>
            </div>
          )}
          {actions.includes('scrollToSchedule') && (
            <div className="pr-2 w-1/2 pt-2 lg:w-auto">
              <Button
                color="secondary"
                onClick={scrollToSchedule}
                size="small"
                variant="transparent"
                fullWidth
                shadow
              >
                View Schedule
              </Button>
            </div>
          )}
          {actions.includes('viewSchedule') && (
            <div className="pr-2 w-1/2 pt-2 lg:w-auto">
              <ButtonLink
                color="secondary"
                href={pathFromUrl(scheduleUrl)}
                size="small"
                variant="transparent"
                fullWidth
                shadow
              >
                View Schedule
              </ButtonLink>
            </div>
          )}
          {actions.includes('bookTruck') && (
            <div className="pr-2 pt-2 w-1/2 lg:w-auto flex-grow">
              <ButtonLink
                color="primary"
                href={{
                  pathname: '/hire-food-truck',
                  query: {
                    truckId
                  }
                }}
                size="small"
                variant="contained"
                fullWidth
                shadow
              >
                Book this truck
              </ButtonLink>
            </div>
          )}
          {actions.filter((a) => !isString(a))}
        </div>
      </div>
    </div>
  )
}

export default TruckActions
