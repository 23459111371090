import React, { useContext } from 'react'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import cn from 'classnames'

const LinkBaseIcon = ({
  className,
  children
}: {
  children: JSX.Element | JSX.Element[]
  className?: string
}) => {
  const { albertsons } = useContext(ShiftPageContext)

  return (
    <div
      className={cn(
        'sm:w-10 sm:h-10 w-8 h-8 shadow-sm rounded-xl transition-all duration-200 rounded-xl bg-gray-100 bg-opacity-20 cursor-pointer hover:bg-opacity-30 flex justify-center items-center',
        className,
        {
          // 'bg-gray-600 hover:bg-gray-500': !albertsons,
          'sm:bg-albertsons-neutral3 bg-albertsons-neutral1 hover:opacity-80': albertsons
        }
      )}
    >
      <div className="flex justify-center items-center">{children}</div>
    </div>
  )
}

export default LinkBaseIcon
