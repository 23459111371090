import Tippy from '@tippyjs/react'
import React, { useState } from 'react'

const Tooltip = ({
  title,
  content,
  button
}: {
  title: string
  content: React.ReactElement | string | null
  button: React.ReactElement
}) => {
  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  return (
    <Tippy
      content={
        <div
          aria-live="assertive"
          className="bg-gray-700 px-2 py-1 text-center rounded-md text-white"
          id="preferredVendorBadge"
          role="tooltip"
        >
          <p>{title}</p>
          {content && <div className="text-xs">{content}</div>}
        </div>
      }
      delay={100}
      onClickOutside={hide}
      visible={visible}
    >
      <div
        aria-describedby="preferredVendorBadge"
        aria-expanded={visible}
        onClick={visible ? hide : show}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            return visible ? hide : show
          }
          return null
        }}
        role="button"
        tabIndex={0}
      >
        {button}
      </div>
    </Tippy>
  )
}

export default Tooltip
