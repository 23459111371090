const smoothVerticalScrolling = ({
  element,
  offset
}: {
  element: Element
  offset: number
}) => {
  // const time = 300
  const bodyRect = document.body.getBoundingClientRect().top
  const elementRect = element.getBoundingClientRect().top
  const elementPosition = elementRect - bodyRect
  const offsetPosition = elementPosition - offset

  try {
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    })
  } catch (e) {
    if ((e as Error).message.includes('2 arguments required')) {
      window.scrollTo(0, offsetPosition)
    } else {
      throw e
    }
  }
  // let curTime = 0
  // while (curTime <= time) {
  //   window.setTimeout(makeScroll, curTime, offsetPosition / 100)
  //   curTime += time / 100
  // }
}

// const makeScroll = (offsetPosition: number) => {
//   window.scrollTo({
//     top: offsetPosition
//   })
// }

export default smoothVerticalScrolling
