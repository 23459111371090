import { ArrowCircleLeftIcon } from '@heroicons/react/outline'
import Link from 'next/link'
import React from 'react'
import cn from 'classnames'
import { useRouter } from 'next/router'

type Params = {
  previousPage?: string
  previousPath?: string
}

type Props = {
  text?: string
  path?: string
  className?: string
}
const PreviousPageLink = ({
  text,
  path,
  className = 'text-gray-200 hover:text-gray-100'
}: Props) => {
  const router = useRouter()
  const { previousPage, previousPath } = router.query as Params
  // eslint-disable-next-line no-param-reassign
  text = text ?? previousPage
  // eslint-disable-next-line no-param-reassign
  path = path ?? previousPath
  return (
    <div
      className={cn({
        visible: path,
        invisible: !path
      })}
    >
      <Link
        className={cn(
          'mb-4 cursor-pointer transition-all duration-200 inline-block',
          className
        )}
        href={path ?? '/'}
        prefetch={false}
      >
        <div className="flex gap-2 items-center">
          <ArrowCircleLeftIcon className="w-5 h-5 inline-block" />
          {text}
        </div>
      </Link>
    </div>
  )
}

export default PreviousPageLink
