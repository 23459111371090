import ForkIcon from 'components/icons/ForkIcon'
import React from 'react'
import Link from 'next/link'
import { gql } from '@apollo/client'
import TruckNameSection from 'components/HeroSection/TruckNameSection'
import TruckActions, { ActionType } from 'components/HeroSection/TruckActions'
import LinksSection, {
  HeroLinksType
} from 'components/HeroSection/LinksSection'
import Rating from 'components/HeroSection/Rating'
import { LocationMarkerIcon } from '@heroicons/react/outline'
import take from 'lodash/take'
import ButtonLink from 'components/ui/ButtonLink'

import PreviousPageLink from '../PreviousPageLink'

import { truckInformationFragment as truckInformationFragmentType } from './types/truckInformationFragment'

export const truckInformationFragment = gql`
  fragment truckInformationFragment on Truck {
    id
    active
    showAsArchived
    preferredVendor
    name
    scheduleUrl
    menuUrl
    facebook
    instagram
    website
    twitter
    tiktok
    preferredVendorBadge
    description {
      html
    }
    foodImage {
      medium
    }
    ratingInfo {
      averageRating
      ratingsCount
      ratings {
        id
      }
    }
    market {
      id
      customerPath
      name
      customerTrucksListPath
    }
    menus {
      id
      active
      catering
      public
      foodTypes {
        id
        name
      }
    }
  }
`

type Props = {
  heroLinks: HeroLinksType | null
  truck: Omit<truckInformationFragmentType, 'market' | 'showAsArchived'> &
    Partial<Pick<truckInformationFragmentType, 'market' | 'showAsArchived'>>
  actions: ActionType[]
  goBackText?: string
  goBackPath?: string
}

const TruckInformationSectionContent = ({
  truck,
  actions,
  heroLinks,
  goBackText,
  goBackPath
}: Props) => {
  const mainMenu =
    truck.menus.find((m) => m.active && m.public && !m.catering) ??
    truck.menus.find((m) => m.active && m.public)
  return (
    <div>
      {actions.includes('goBack') && (
        <PreviousPageLink path={goBackPath} text={goBackText} />
      )}
      <TruckNameSection
        name={truck.name}
        preferredVendor={truck.preferredVendor}
        preferredVendorBadge={truck.preferredVendorBadge}
      />
      <Rating ratingInfo={truck.ratingInfo} />

      {truck.market && (
        <div className="mt-2 mb-2 italic text-white flex items-center">
          <div className="inline mr-2">
            <LocationMarkerIcon className="w-6" />
          </div>
          {actions.includes('market') ? (
            <Link
              className="underline"
              href={truck.market.customerPath}
              prefetch={false}
            >
              {truck.market.name}
            </Link>
          ) : (
            <span>{truck.market.name}</span>
          )}
        </div>
      )}
      <div className="mt-2 mb-5 italic text-white flex items-center">
        {mainMenu && (
          <>
            <div className="inline mr-2">
              <ForkIcon />
            </div>
            {take(mainMenu.foodTypes, 5)
              .map((t) => t.name)
              .join(', ')}
          </>
        )}
      </div>

      {!truck.showAsArchived && (
        <TruckActions
          actions={actions}
          menuUrl={truck.menuUrl}
          scheduleUrl={truck.scheduleUrl}
          truckId={truck.id}
        />
      )}
      {truck.showAsArchived && truck.market && (
        <div className="mt-6">
          <ButtonLink
            className="mt-2"
            color="primary"
            href={truck.market.customerTrucksListPath}
            variant="contained"
          >
            <>Food trucks in {truck.market.name}</>
          </ButtonLink>
        </div>
      )}
      {actions.includes('socialMedia') && heroLinks && !truck.showAsArchived && (
        <div className="sm:mt-6 hidden sm:block">
          <LinksSection socialLinks={heroLinks} />
        </div>
      )}
    </div>
  )
}
export default TruckInformationSectionContent
