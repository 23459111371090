import { StarIcon } from '@heroicons/react/solid'
import React from 'react'
import { truckInformationFragment_ratingInfo } from 'components/HeroSection/types/truckInformationFragment'

const Rating = ({
  ratingInfo
}: {
  ratingInfo: truckInformationFragment_ratingInfo | null
}) => {
  if (!ratingInfo) return null
  if (ratingInfo.ratingsCount <= 0) return null

  return (
    <div className="mt-3 italic text-white flex items-center">
      <StarIcon className="w-6 h-6 mr-2 text-orange-400" />
      <p className="mr-2">{ratingInfo.averageRating}</p>
      {ratingInfo.ratings.length > 0 && (
        <a
          className="underline"
          href="#ratings"
          onClick={(e) => {
            e.preventDefault()
            document.querySelector('#ratings')?.scrollIntoView({
              behavior: 'smooth'
            })
          }}
        >
          <p>({ratingInfo.ratingsCount} reviews)</p>
        </a>
      )}
    </div>
  )
}

export default Rating
