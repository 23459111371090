import Tooltip from 'components/ui/Tooltip'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { CheckCircleIcon as CheckCircleIconOutline } from '@heroicons/react/outline'
import React, { useContext } from 'react'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import cn from 'classnames'

type Props = {
  name: string
  preferredVendor: boolean
  preferredVendorBadge: string | null
}
const TruckNameSection = ({
  name,
  preferredVendor,
  preferredVendorBadge
}: Props) => {
  const { albertsons } = useContext(ShiftPageContext)
  return (
    <div className="mb-4 lg:mb-0 flex flex-row flex-nowrap items-start justify-start">
      <h1 className="sm:text-5xl text-4xl text-white font-extrabold mr-4 line-clamp-2 pb-1">
        {name}
      </h1>
      {preferredVendor && (
        <div className="ml-2">
          <div className="flex flex-row items-center mt-2 whitespace-pre-wrap">
            <Tooltip
              button={
                <div className="flex items-center">
                  <CheckCircleIcon
                    className={cn('sm:block hidden w-8 h-8 text-orange-500', {
                      'text-orange-500': !albertsons,
                      'text-albertsons-pink-lemonade': albertsons
                    })}
                  />
                  <CheckCircleIconOutline
                    className={cn('block sm:hidden w-8 h-8', {
                      'text-orange-500': !albertsons,
                      'text-albertsons-pink-lemonade': albertsons
                    })}
                  />
                  <p className="lg:block hidden ml-1 text-sm text-gray-200">
                    Preferred Vendor
                  </p>
                </div>
              }
              content={preferredVendorBadge}
              title="Preferred Vendor"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default TruckNameSection
